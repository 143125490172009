<template>
  <div v-if="isOpen" class="modal-overlay" @click="handleClose">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h3>Totals</h3>
        <button class="close-button" @click="handleClose">&times;</button>
      </div>
      <div class="modal-body">
        <p class="instruction">Enter what each player has:</p>

        <div class="input-headers">
          <span class="player-name-header">Player</span>
          <span class="unit-header">Diamonds</span>
          <span class="unit-header">Queens</span>
          <span class="unit-header">Hands</span>
          <span class="unit-header">King ♥</span>
        </div>

        <div class="player-list">
          <div v-for="player in players" :key="player.id" class="player-input-row">
            <span class="player-name">{{ player.name }}</span>
            <button class="player-units-button" @click="openNumberSelector(player.id, 'diamonds')">
              {{ playerTotals[player.id]?.diamonds ?? 0 }}
            </button>
            <button class="player-units-button" @click="openNumberSelector(player.id, 'queens')">
              {{ playerTotals[player.id]?.queens ?? 0 }}
            </button>
            <button class="player-units-button" @click="openNumberSelector(player.id, 'hands')">
              {{ playerTotals[player.id]?.hands ?? 0 }}
            </button>
            <input
              type="checkbox"
              :checked="kingOfHeartsPlayerId === player.id"
              class="king-checkbox"
              @change="handleKingSelection(player.id)"
            />
          </div>
        </div>

        <div v-if="!isValid" class="error-message">
          {{ validationErrorMessage }}
        </div>
      </div>
      <div class="modal-footer">
        <button class="confirm-button" :disabled="!isValid" @click="handleConfirm">Confirm</button>
      </div>
    </div>

    <NumberSelectorModal
      :is-open="isNumberSelectorOpen"
      :max-units="currentMaxUnitsForSelector"
      :current-player-name="playerToEditName"
      :initial-value="initialValueForSelector"
      @select="handleNumberSelect"
      @close="closeNumberSelector"
    />
  </div>
</template>

<script setup>
import { ref, watch, computed, reactive, onMounted } from 'vue';
import { useGameConfigStore } from '../stores/gameConfig';
import { GAME_TYPES } from '../constants/gameTypes';
import NumberSelectorModal from './NumberSelectorModal.vue';

const props = defineProps({
  isOpen: Boolean,
  players: Array
});

const emit = defineEmits(['confirm', 'close']);

const gameConfig = useGameConfigStore();

const playerTotals = ref({});
const kingOfHeartsPlayerId = ref(null);

const expectedTotalDiamonds = computed(() =>
  gameConfig.games[GAME_TYPES.DIAMONDS].maxUnits(props.players.length)
);
const expectedTotalQueens = computed(() => gameConfig.games[GAME_TYPES.QUEENS].maxUnits());
const expectedTotalHands = computed(() => gameConfig.games[GAME_TYPES.LEVATE].maxUnits());

const initializeState = () => {
  const totals = {};
  props.players.forEach((player) => {
    totals[player.id] = reactive({
      diamonds: 0,
      queens: 0,
      hands: 0
    });
  });
  playerTotals.value = totals;
  kingOfHeartsPlayerId.value = null;
  closeNumberSelector();
};

watch(
  () => props.isOpen,
  (newVal) => {
    if (newVal) {
      initializeState();
    }
  }
);

watch(
  () => props.players,
  () => {
    if (props.isOpen) {
      initializeState();
    }
  },
  { deep: true }
);

onMounted(() => {
  if (props.isOpen) {
    initializeState();
  }
});

const handleKingSelection = (playerId) => {
  if (kingOfHeartsPlayerId.value === playerId) {
    kingOfHeartsPlayerId.value = null;
  } else {
    kingOfHeartsPlayerId.value = playerId;
  }
};

const playerToEditName = computed(() => {
  return props.players.find((p) => p.id === playerToEditId.value)?.name || '';
});

const isNumberSelectorOpen = ref(false);
const playerToEditId = ref(null);
const unitTypeToEdit = ref(null);

const currentMaxUnitsForSelector = computed(() => {
  switch (unitTypeToEdit.value) {
    case 'diamonds':
      return expectedTotalDiamonds.value;
    case 'queens':
      return expectedTotalQueens.value;
    case 'hands':
      return expectedTotalHands.value;
    default:
      return 0;
  }
});

const initialValueForSelector = computed(() => {
  if (!playerToEditId.value || !unitTypeToEdit.value) return 0;
  return playerTotals.value[playerToEditId.value]?.[unitTypeToEdit.value] ?? 0;
});

const openNumberSelector = (playerId, unitType) => {
  playerToEditId.value = playerId;
  unitTypeToEdit.value = unitType;
  isNumberSelectorOpen.value = true;
};

const closeNumberSelector = () => {
  isNumberSelectorOpen.value = false;
  playerToEditId.value = null;
  unitTypeToEdit.value = null;
};

const handleNumberSelect = (units) => {
  if (playerToEditId.value && unitTypeToEdit.value) {
    playerTotals.value[playerToEditId.value][unitTypeToEdit.value] = units;
  }
  closeNumberSelector();
};

const currentTotalDiamonds = computed(() => {
  return Object.values(playerTotals.value).reduce((sum, p) => sum + (p.diamonds ?? 0), 0);
});
const currentTotalQueens = computed(() => {
  return Object.values(playerTotals.value).reduce((sum, p) => sum + (p.queens ?? 0), 0);
});
const currentTotalHands = computed(() => {
  return Object.values(playerTotals.value).reduce((sum, p) => sum + (p.hands ?? 0), 0);
});

const isValid = computed(() => {
  if (!kingOfHeartsPlayerId.value) return false;
  if (!props.players || props.players.length === 0) return false;

  return (
    currentTotalQueens.value === expectedTotalQueens.value &&
    currentTotalDiamonds.value === expectedTotalDiamonds.value &&
    currentTotalHands.value === expectedTotalHands.value
  );
});

const validationErrorMessage = computed(() => {
  const errors = [];
  if (!kingOfHeartsPlayerId.value) {
    errors.push('Select who has the King of Hearts.');
  }
  if (currentTotalDiamonds.value !== expectedTotalDiamonds.value) {
    const diff = expectedTotalDiamonds.value - currentTotalDiamonds.value;
    if (diff > 0) {
      errors.push(`${diff} Diamond${diff !== 1 ? 's' : ''} remaining.`);
    } else {
      errors.push(`Diamonds exceeded by ${Math.abs(diff)}.`);
    }
  }
  if (currentTotalQueens.value !== expectedTotalQueens.value) {
    const diff = expectedTotalQueens.value - currentTotalQueens.value;
    if (diff > 0) {
      errors.push(`${diff} Queen${diff !== 1 ? 's' : ''} remaining.`);
    } else {
      errors.push(`Queens exceeded by ${Math.abs(diff)}.`);
    }
  }
  if (currentTotalHands.value !== expectedTotalHands.value) {
    const diff = expectedTotalHands.value - currentTotalHands.value;
    if (diff > 0) {
      errors.push(`${diff} Hand${diff !== 1 ? 's' : ''} remaining.`);
    } else {
      errors.push(`Hands exceeded by ${Math.abs(diff)}.`);
    }
  }

  return errors.join(' ');
});

const handleConfirm = () => {
  if (isValid.value) {
    const confirmData = {
      [GAME_TYPES.KING_OF_HEARTS]: kingOfHeartsPlayerId.value,
      [GAME_TYPES.DIAMONDS]: {},
      [GAME_TYPES.QUEENS]: {},
      [GAME_TYPES.LEVATE]: {}
    };

    for (const playerId in playerTotals.value) {
      confirmData[GAME_TYPES.DIAMONDS][playerId] = playerTotals.value[playerId].diamonds;
      confirmData[GAME_TYPES.QUEENS][playerId] = playerTotals.value[playerId].queens;
      confirmData[GAME_TYPES.LEVATE][playerId] = playerTotals.value[playerId].hands;
    }

    emit('confirm', confirmData);
  }
};

const handleClose = () => {
  emit('close');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--bg-color);
  padding: 1.5rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.75rem;
  margin-bottom: 1rem;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--text-color);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-secondary);
  cursor: pointer;
}

.modal-body {
  margin-bottom: 1.5rem;
}

.instruction {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.input-headers,
.player-input-row {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  gap: 0.5rem;
}

.input-headers {
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
}

.player-input-row {
  border-bottom: 1px solid var(--surface-color);
}

.player-list {
  max-height: 300px;
  overflow-y: auto;
}

.player-name-header,
.player-name {
  flex: 2;
  text-align: left;
  font-weight: bold;
  font-size: 0.9rem;
  padding-right: 0.5rem;
}

.unit-header {
  flex: 1;
  text-align: center;
}

.player-units-button {
  flex: 1;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0.4rem 0;
  text-align: center;
  font-size: 0.9rem;
  color: var(--text-color);
  cursor: pointer;
  min-width: 40px;
  transition: background-color 0.2s;
}

.player-units-button:hover {
  background-color: var(--border-color);
}

.king-checkbox {
  flex: 1;
  margin: 0 auto;
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.error-message {
  color: #ff4444;
  font-size: 0.85rem;
  margin-top: 1rem;
  text-align: center;
  white-space: normal;
  line-height: 1.4;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.confirm-button {
  padding: 0.6rem 1.2rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.confirm-button:disabled {
  background-color: var(--surface-color);
  color: var(--text-secondary);
  cursor: not-allowed;
}

.confirm-button:not(:disabled):hover {
  background-color: var(--primary-hover);
}
</style>
