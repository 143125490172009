import { defineStore } from 'pinia';
import { useGameConfigStore } from './gameConfig';

export const useGameStore = defineStore('game', {
  state: () => ({
    players: [],
    currentPlayerIndex: 0,
    currentRound: 1,
    rounds: [],
    miniGames: []
  }),

  getters: {
    maxRound: (state) => state.players.length * state.miniGames.length,
    hasActiveGame: (state) =>
      state.players.length > 0 &&
      state.rounds.length < state.players.length * state.miniGames.length,

    isGameOver: (state) => {
      if (state.players.length === 0) return false;
      const totalRounds = state.players.length * state.miniGames.length;
      return state.rounds.length >= totalRounds;
    },

    winner: (state) => {
      if (!state.isGameOver) return null;
      const playerScores = state.players.map((player, index) => ({
        name: player.name,
        score: state.rounds.reduce((sum, round) => {
          const playerScore = round.scores.find((score) => score.playerId === player.id);
          return sum + (playerScore ? playerScore.score : 0);
        }, 0)
      }));
      return playerScores.reduce((winner, player) =>
        player.score > winner.score ? player : winner
      );
    },

    currentPlayerId: (state) => state.players[state.currentPlayerIndex]?.id,

    availableMiniGames: (state) => {
      const currentPlayerId = state.players[state.currentPlayerIndex]?.id;
      if (!currentPlayerId) return [];

      const playedGames = state.rounds
        .filter((round) => round.miniGame.selectedByPlayerId === currentPlayerId)
        .map((round) => round.miniGame?.id)
        .filter(Boolean);
      const gameConfig = useGameConfigStore();
      return Object.values(gameConfig.games).filter(
        (game) => state.miniGames.includes(game.id) && !playedGames.includes(game.id)
      );
    },

    playerScores: (state) => {
      const totals = state.players.map((player) => {
        const total = state.rounds.reduce((sum, round) => {
          const playerScore = round.scores.find((score) => score.playerId === player.id);
          return sum + (playerScore ? playerScore.score : 0);
        }, 0);

        const lastRound = state.rounds[state.rounds.length - 1];
        const roundScore = lastRound
          ? lastRound.scores.find((score) => score.playerId === player.id)?.score || 0
          : 0;

        return {
          total,
          round: roundScore
        };
      });

      return totals;
    },

    getPlayerRank: (state) => (index) => {
      const playerScores = state.players.map((player, idx) => ({
        score: state.rounds.reduce((sum, round) => {
          const playerScore = round.scores.find((score) => score.playerId === player.id);
          return sum + (playerScore ? playerScore.score : 0);
        }, 0),
        index: idx
      }));

      const sortedScores = playerScores.sort((a, b) => b.score - a.score);
      return sortedScores.findIndex((item) => item.index === index) + 1;
    },

    playerNames: (state) => state.players.map((player) => player.name)
  },

  actions: {
    resetGame() {
      this.players = [];
      this.currentPlayerIndex = 0;
      this.currentRound = 1;
      this.rounds = [];
      this.miniGames = [];
      this.saveGameState();
    },

    saveGameState() {
      const gameState = {
        players: this.players,
        currentPlayerIndex: this.currentPlayerIndex,
        currentRound: this.currentRound,
        rounds: this.rounds,
        miniGames: this.miniGames
      };
      localStorage.setItem('gameState', JSON.stringify(gameState));
    },

    loadGameState() {
      const savedState = localStorage.getItem('gameState');
      if (savedState) {
        const gameState = JSON.parse(savedState);
        this.players = gameState.players;
        this.currentPlayerIndex = gameState.currentPlayerIndex;
        this.currentRound = gameState.currentRound;
        this.rounds = gameState.rounds;
        this.miniGames = gameState.miniGames || [];
      }
    },

    initializeGame(playerNames, gameType = 'full') {
      this.players = playerNames
        .filter((name) => name.trim())
        .map((name) => ({
          id: crypto.randomUUID(),
          name: name.trim()
        }));

      this.currentPlayerIndex = 0;
      this.currentRound = 1;
      this.rounds = [];

      const gameConfig = useGameConfigStore();
      this.miniGames =
        gameType === 'full' ? Object.values(gameConfig.games).map((game) => game.id) : [];

      this.saveGameState();
    },

    setMiniGames(selectedMiniGames) {
      this.miniGames = selectedMiniGames;
      this.saveGameState();
    },

    addRoundScores(scores, miniGameId, isDouble = false) {
      const newRound = {
        roundNumber: this.currentRound,
        miniGame: {
          id: miniGameId,
          isDouble,
          selectedByPlayerId: this.currentPlayerId
        },
        scores: scores.map(({ playerId, score }) => ({
          playerId,
          score: isDouble ? score * 2 : score
        }))
      };

      this.rounds.push(newRound);
      this.saveGameState();
    },

    startNextRound() {
      this.currentRound++;
      this.currentPlayerIndex = (this.currentPlayerIndex + 1) % this.players.length;
      this.saveGameState();
    }
  }
});
