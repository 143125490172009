<template>
  <div v-if="isOpen" class="modal-overlay" @click="handleClose">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h3>{{ selectedGame }}</h3>
        <button class="close-button" @click="handleClose">&times;</button>
      </div>
      <div class="modal-body">
        <p class="instruction">
          Select the player who has the
          {{ selectedGame }}
        </p>
        <div class="players-grid">
          <button
            v-for="player in players"
            :key="player.id"
            :class="{ selected: selectedPlayerId === player.id }"
            @click="selectPlayer(player.id)"
          >
            {{ player.name }}
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button class="confirm-button" :disabled="!selectedPlayerId" @click="handleConfirm">
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { GAME_TYPES } from '../constants/gameTypes';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  selectedGame: {
    type: [String, null],
    required: true,
    validator: (value) =>
      value === null || [GAME_TYPES.KING_OF_HEARTS, GAME_TYPES.TEN_OF_CLUBS].includes(value)
  },
  players: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['confirm', 'close']);

const selectedPlayerId = ref(null);

const selectPlayer = (playerId) => {
  selectedPlayerId.value = playerId;
};

const handleConfirm = () => {
  if (selectedPlayerId.value) {
    emit('confirm', selectedPlayerId.value);
    selectedPlayerId.value = null;
  }
};

const handleClose = () => {
  selectedPlayerId.value = null;
  emit('close');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  color: var(--text-color);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
}

.close-button:hover {
  color: var(--text-color);
}

.instruction {
  color: var(--text-secondary);
  margin: 0;
  text-align: center;
}

.players-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.75rem;
  margin: 1rem 0;
}

.players-grid button {
  padding: 0.75rem;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.players-grid button:hover {
  background-color: var(--border-color);
}

.players-grid button.selected {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.confirm-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.confirm-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.confirm-button:not(:disabled):hover {
  opacity: 0.9;
}

@media (max-width: 480px) {
  .modal-content {
    padding: 1rem;
    width: 95%;
  }

  .players-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .players-grid button {
    padding: 0.5rem;
  }
}
</style>
