import { defineStore } from 'pinia';
import { GAME_TYPES } from '../constants/gameTypes';
import { SCORING_TYPES } from '../constants/scoringTypes';

const calculateUnitBasedScore = (scoreInput, gamePoints) => {
  const scores = [];
  for (const playerId in scoreInput) {
    const unitCount = scoreInput[playerId];
    scores.push({
      playerId: playerId,
      score: unitCount * gamePoints
    });
  }
  return scores;
};

const calculateBooleanScore = (playerWithCard, gamePoints, players) => {
  const scores = [];
  for (const player of players) {
    scores.push({
      playerId: player.id,
      score: playerWithCard === player.id ? gamePoints : 0
    });
  }
  return scores;
};

export const useGameConfigStore = defineStore('gameConfig', {
  state: () => ({
    games: {
      [GAME_TYPES.DIAMONDS]: {
        id: GAME_TYPES.DIAMONDS,
        displayName: 'Diamonds',
        symbol: '♦',
        scoringType: SCORING_TYPES.UNIT_BASED,
        points: -20,
        maxUnits: (playerCount) => (playerCount * 8) / 4,
        description: 'Lose points for each diamond',
        calculateScore: calculateUnitBasedScore
      },
      [GAME_TYPES.QUEENS]: {
        id: GAME_TYPES.QUEENS,
        displayName: 'Queens',
        symbol: 'Q',
        scoringType: SCORING_TYPES.UNIT_BASED,
        points: -25,
        maxUnits: () => 4,
        description: 'Lose points for each queen',
        calculateScore: calculateUnitBasedScore
      },
      [GAME_TYPES.LEVATE]: {
        id: GAME_TYPES.LEVATE,
        displayName: 'Levate',
        symbol: '-',
        scoringType: SCORING_TYPES.UNIT_BASED,
        points: -20,
        maxUnits: () => 8,
        description: 'Lose points for each hand',
        calculateScore: calculateUnitBasedScore
      },
      [GAME_TYPES.TEN_OF_CLUBS]: {
        id: GAME_TYPES.TEN_OF_CLUBS,
        displayName: '10 ♣',
        symbol: '10♣',
        scoringType: SCORING_TYPES.BOOLEAN,
        points: 100,
        maxUnits: () => 1,
        description: 'Gain points if you have the ten of clubs',
        calculateScore: calculateBooleanScore
      },
      [GAME_TYPES.KING_OF_HEARTS]: {
        id: GAME_TYPES.KING_OF_HEARTS,
        displayName: 'K ♥',
        symbol: 'K♥',
        scoringType: SCORING_TYPES.BOOLEAN,
        points: -300,
        maxUnits: () => 1,
        description: 'Lose points if you have the king of hearts',
        calculateScore: calculateBooleanScore
      },
      [GAME_TYPES.WHIST]: {
        id: GAME_TYPES.WHIST,
        displayName: 'Whist',
        symbol: '+',
        scoringType: SCORING_TYPES.UNIT_BASED,
        points: 20,
        maxUnits: () => 8,
        description: 'Gain points for each hand',
        calculateScore: calculateUnitBasedScore
      },
      [GAME_TYPES.RENTZ]: {
        id: GAME_TYPES.RENTZ,
        displayName: 'Rentz',
        symbol: 'R',
        scoringType: SCORING_TYPES.POSITION_BASED,
        points: 100,
        getPositionPoints(playerCount) {
          const points = [];
          for (let i = playerCount - 1; i >= 0; i--) {
            points.unshift(i === playerCount - 1 ? 0 : (playerCount - i - 1) * this.points);
          }
          return points;
        },
        maxUnits: () => 1,
        description: 'Score points based on completion order',
        calculateScore: (orderedPlayers, gamePoints, players) => {
          const positionPoints = [];
          const playerCount = players.length;
          for (let i = playerCount - 1; i >= 0; i--) {
            positionPoints.unshift(i === playerCount - 1 ? 0 : (playerCount - i - 1) * gamePoints);
          }

          const scores = orderedPlayers.map((player, index) => ({
            playerId: player.id,
            score: positionPoints[index]
          }));

          return scores;
        }
      },
      [GAME_TYPES.TOTALS]: {
        id: GAME_TYPES.TOTALS,
        displayName: 'Totals',
        symbol: 'Σ',
        scoringType: SCORING_TYPES.TOTALS,
        includedGames: [
          GAME_TYPES.DIAMONDS,
          GAME_TYPES.QUEENS,
          GAME_TYPES.LEVATE,
          GAME_TYPES.KING_OF_HEARTS
        ],
        description: 'Lose points for Diamonds, Queens, hands and the King of Heart',
        calculateScore: (scoreInput, game, players, state) => {
          //TODO: check how this can be refactored to avoid passing the state

          const scores = players.map((player) => ({
            playerId: player.id,
            score: 0
          }));

          for (const subGameId of game.includedGames) {
            const subGame = state.games[subGameId];
            const gameScoreInput = scoreInput[subGameId];
            const gameScores =
              subGameId === GAME_TYPES.KING_OF_HEARTS
                ? subGame.calculateScore(gameScoreInput, subGame.points, players)
                : subGame.calculateScore(gameScoreInput, subGame.points);
            for (const player of gameScores) {
              scores.find((p) => p.playerId === player.playerId).score += player.score;
            }
          }
          return scores;
        }
      }
    }
  })
});
