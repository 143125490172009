<template>
  <div v-if="isOpen" class="number-selector-overlay" @click="emit('close')">
    <div class="number-selector-content" @click.stop>
      <h4>Select Units for {{ currentPlayerName }}</h4>
      <div class="number-grid">
        <button
          v-for="n in Array.from({ length: maxUnits + 1 }, (_, i) => i)"
          :key="n"
          class="number-button"
          @click="handleSelect(n)"
        >
          {{ n }}
        </button>
      </div>
      <button class="close-selector-button" @click="emit('close')">Close</button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  maxUnits: {
    type: Number,
    required: true
  },
  currentPlayerName: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['select', 'close']);

const handleSelect = (number) => {
  emit('select', number);
};
</script>

<style scoped>
.number-selector-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  border-radius: 8px;
}

.number-selector-content {
  background-color: var(--bg-color);
  padding: 1.5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 80%;
  max-width: 300px;
}

.number-selector-content h4 {
  margin: 0;
  color: var(--text-color);
  text-align: center;
}

.number-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  gap: 0.5rem;
  width: 100%;
}

.number-button {
  padding: 0.75rem 0.25rem;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
  text-align: center;
}

.number-button:hover:not(:disabled) {
  background-color: var(--border-color);
}

.close-selector-button {
  padding: 0.5rem 1rem;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-secondary);
  cursor: pointer;
}

@media (max-width: 480px) {
  .number-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.4rem;
  }
  .number-button {
    padding: 0.6rem 0.2rem;
  }
  .number-selector-content {
    width: 90%;
  }
}
</style>
