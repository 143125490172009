<template>
  <div class="new-game">
    <h3>Game Setup</h3>
    <div class="game-type">
      <label> <input v-model="gameType" type="radio" value="full" /> Full Game </label>
      <label> <input v-model="gameType" type="radio" value="short" /> Short Game </label>
    </div>
    <div class="player-count">
      <label>Number of Players:</label>
      <div class="player-count-buttons">
        <button
          v-for="count in 4"
          :key="count + 2"
          :class="{ active: playerCount === count + 2 }"
          @click="updatePlayerCount(count + 2)"
        >
          {{ count + 2 }}
        </button>
      </div>
    </div>
    <div class="player-names">
      <h4>Player Names</h4>
      <div v-for="n in playerCount" :key="n" class="player-input">
        <label :for="'player' + n">Player {{ n }}:</label>
        <div class="input-wrapper">
          <input :id="'player' + n" v-model="playerNames[n - 1]" />
          <span v-if="isNameDuplicate(playerNames[n - 1])" class="error-message">
            Multiple players have the same name
          </span>
        </div>
      </div>
    </div>
    <button v-if="gameType === 'full'" :disabled="!isValid" @click="startGame">Start Game</button>
    <button v-else :disabled="!isValid" @click="goToMiniGameSelection">Next</button>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useGameStore } from '../stores/game';

const router = useRouter();
const store = useGameStore();

const playerCount = ref(3);
const playerNames = ref(['', '', '', '', '', '']);
const gameType = ref('full');

const duplicateNames = computed(() => {
  const activeNames = playerNames.value.slice(0, playerCount.value);
  const nameCounts = {};
  const duplicates = new Set();

  activeNames.forEach((name) => {
    const trimmedName = name.trim();
    if (trimmedName) {
      nameCounts[trimmedName] = (nameCounts[trimmedName] || 0) + 1;
      if (nameCounts[trimmedName] > 1) {
        duplicates.add(trimmedName);
      }
    }
  });

  return duplicates;
});

const isNameDuplicate = (name) => {
  return duplicateNames.value.has(name.trim());
};

const isValid = computed(() => {
  const activeNames = playerNames.value.slice(0, playerCount.value);
  const hasEmptyNames = activeNames.some((name) => name.trim() === '');
  const hasDuplicateNames =
    new Set(activeNames.map((name) => name.trim())).size !== activeNames.length;

  return playerCount.value >= 3 && playerCount.value <= 6 && !hasEmptyNames && !hasDuplicateNames;
});

const updatePlayerCount = (count) => {
  playerCount.value = count;
  playerNames.value = playerNames.value.map((name, index) => (index < count ? name : ''));
};

const startGame = () => {
  if (isValid.value) {
    const activePlayerNames = playerNames.value.slice(0, playerCount.value);
    store.initializeGame(activePlayerNames);
    router.push('/game');
  }
};

const goToMiniGameSelection = () => {
  if (isValid.value) {
    const activePlayerNames = playerNames.value.slice(0, playerCount.value);
    store.initializeGame(activePlayerNames);
    router.push('/select-mini-games');
  }
};

onMounted(() => {
  store.resetGame();
});
</script>

<style scoped>
.new-game {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.game-type {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.player-count {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.player-count-buttons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.player-count-buttons button {
  flex: 1;
  min-width: 80px;
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
}

.player-count-buttons button.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.player-names {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.player-input {
  display: flex;
  gap: 1rem;
  align-items: center;
}

label {
  min-width: 100px;
  color: var(--text-secondary);
}

h2,
h3,
h4 {
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

h3 {
  color: var(--primary-color);
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.error-message {
  color: var(--error-color, #dc3545);
  font-size: 0.875rem;
}
</style>
